<template>
  <v-card
    :loading="loading"
  >
    <v-card-title>
      {{ title }}
      <v-spacer />
      <v-btn
        :disabled="disabled"
        text
        small
        color="blue lighten-1"
        @click="modal.opened = true"
      >
        Modifier
      </v-btn>
    </v-card-title>
    <v-card-text>
      <AddressDetail
        :address="address"
        :email="email"
        :phone="phone"
      />
    </v-card-text>
    <AddressModal
      :opened.sync="modal.opened"
      :type="type"
      :address="address"
      :email="email"
      :phone="phone"
      :title="$t('views.shipment.forms.title.address_update.' + type)"
      @submited="save"
    />
  </v-card>
</template>

<script>
import AddressDetail from '@/components/core/AddressDetail'
import AddressModal from '@/views/Shipments/Edit/AddressModal.vue'

import { countryCodeEmoji } from '@/helpers/countries'

export default {
  name: 'Address',
  components: { AddressDetail, AddressModal },
  props: {
    type: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    shipmentId: {
      type: String,
      required: true,
    },
    address: {
      type: Object,
      default: () => ({}),
    },
    email: {
      type: String,
      default: null,
    },
    phone: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      message: {
        type: null,
        text: null,
      },
      modal: {
        opened: false,
      },
    }
  },
  methods: {
    countryCodeEmoji,
    close() {
      this.$emit('shipmentUpdated', this.message)
      this.loading = false
    },
    save(data) {
      if (!['recipient', 'sender'].includes(this.type)) {
        return
      }
      
      this.loading = true
      this.$axios.put('v3/shipments/' + this.shipmentId + '/' + this.type, data)
        .then(() => {})
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error)
          let error_message = error.response.data['hydra:description'] ?? error.response.data['detail'] ?? 'No context'
          this.message.type = 'error'
          this.message.text = this.$i18n.t('views.shipment.forms.messages.save_failed', { error: error_message })
        }).finally(() => {
          this.close()
        })
    },
  },
}
</script>

<style scoped>

</style>
